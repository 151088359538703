<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Create new company
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="card material-card card-form-container"
    >
      <form
        slot="Pre-header"
        action=""
        @submit.prevent="validateBeforeSubmit"
      >
        <card
          class="modal-card-body"
          external-card="card material-card"
          internal-card="material-card-content"
          url-help=""
        >
          <section
            slot="header"
            style="max-width: 100%; position: relative;margin-bottom:20px"
          >
            <b-notification
              v-if="companiesFromStore && membershipFetched"
              has-icon
              icon="progress-check"
              aria-close-label="Close notification"
            >
              Welcome to Boardgent, your account is almost ready!
              Now create the company where you will add your computers.
            </b-notification>
          </section>
          <section
            slot="body"
          >
            <template v-for="(parameters,i) in companySettings">
              <b-field
                :key="i"
                :label="parameters.title"
                :type="errors.has(parameters.name) ? 'is-danger':''"
                :message="errors.has(parameters.name) ? errors.first(parameters.name):''"
              >
                <b-input
                  v-if="parameters.type === 'text'"
                  v-model="company[parameters.name]"
                  v-validate="parameters.validate"
                  :type="parameters.type"
                  :name="parameters.name"
                  :placeholder="parameters.placeholder"
                />
                <b-select
                  v-else-if="parameters.type === 'select'"
                  v-model="company[parameters.name]"
                  v-validate="parameters.validate"
                  :type="parameters.type"
                  :placeholder="parameters.placeholder"
                  :name="parameters.name"
                  expanded
                >
                  <template
                    v-for="({name,value},index) in companySizeValues"
                  >
                    <option
                      v-if="value !== 0"
                      :key="index"
                      :value="value"
                    >
                      {{ name }}
                    </option>
                  </template>
                </b-select>
                <div
                  v-if="parameters.type === 'radio'"
                  class="block mb-0"
                >
                  <b-radio
                    v-for="reason in reasonOfUseArray"
                    :key="reason.value.value"
                    v-model="company[parameters.name]"
                    v-validate="parameters.validate"
                    :name="parameters.name"
                    :native-value="reason.value.value"
                  >
                    {{ reason.value.name }}
                  </b-radio>
                </div>
              </b-field>
            </template>
            <button
              :class="{'is-loading': isLoading }"
              class="button is-primary main-card-form-button"
              style="margin-bottom: -10px; margin-top:20px;"
            >
              Create
            </button>
          </section>
        </card>
        <b-loading
          :is-full-page="isFullLoading"
          :active="isLoading"
          :can-cancel="false"
        />
      </form>
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import snackBarMessage from '@/helpers/snackBarMessage';

import card from '@/components/cross/Card.vue';

import ErrorMixin from '@/mixins/error';
import analyticsMixin from '@/mixins/analytics';

import vtulEnums from '../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;
const parametersCompany = [{
  title: 'Company name',
  name: 'name',
  type: 'text',
  validate: 'required',
},
{
  title: 'Company country',
  name: 'country',
  type: 'text',
  validate: 'required',
},
{
  title: 'Company city',
  name: 'city',
  type: 'text',
  validate: 'required',
},
{
  title: 'I am using Boardgent for:',
  name: 'reason',
  type: 'radio',
  validate: 'required',
},
{
  title: 'Company size',
  name: 'size',
  placeholder: 'Number of employees',
  type: 'select',
  validate: 'required|between:1,10',
},
{
  title: 'Company URL',
  name: 'url',
  placeholder: '(Optional)',
  type: 'text',
  validate: 'url',
}];
export default {
  name: 'CreateCompany',
  metaInfo: {
    title: 'Create new company',
  },
  components: {
    card,
  },
  mixins: [ErrorMixin, analyticsMixin],
  data() {
    return {
      company: {},
      companySettings: parametersCompany,
      isLoading: false,
      isFullLoading: false,
      companySizeValues: vtulEnums.enum.company.sizeTypes.values.value,
      reasonOfUseEnum: vtulEnums.enum.company.reasonOfUse,
      membershipFetched: false,
    };
  },
  computed: {
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    user() {
      return this.$store.getters['auth/user'];
    },
    companiesFromStore() {
      const companiesAmount = this.findMembershipsInStore({
        query: {
          $limit: 1,
          userId: this.user.id,
        },
      }).total;
      return (companiesAmount === 0);
    },
    reasonOfUseArray() {
      return this.reasonOfUseEnum.enums.slice(1);
    },
  },
  created() {
    this.fetchMembershipFromApi();
  },
  methods: {
    ...mapActions('companies', {
      createCompany: 'create',
      updateCompany: 'patch',
      removeCompany: 'remove',
    }),
    ...mapActions('companies', ['changeCurrentCompany']),
    ...mapActions('stripe-company-information', { createStripeCustomer: 'create' }),
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapMutations(['clearCurrentSelections']),
    validateBeforeSubmit() {
      if (!this.$isBgCloud && !this.companiesFromStore) {
        snackBarMessage.showError('You cannot create more than 1 company.');
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.registerCompany({
              name: `${this.company.name.charAt(0).toUpperCase()}${this.company.name.slice(1)}`,
              country: this.company.country,
              city: this.company.city,
              reasonOfUse: this.company.reason,
              url: this.company.url,
              size: this.company.size,
            });
          }
        }).catch((error) => { throw new Error(error); });
      }
    },
    async registerCompany(formData) {
      this.isLoading = true;
      this.isFullLoading = true;
      try {
        this.trackEvent(!this.user.isTestData, 'track', 'Create Company', formData);
        this.company = await this.createCompany(formData);
        if (this.$isBgCloud) {
          const stripeData = await this.createStripeCustomer([{
            id: this.company.id,
          }, { query: { companyId: this.company.id } }]);
          this.company = await this.updateCompany([this.company.id, stripeData, {}]);
        }
        this.clearCurrentSelections();
        this.changeCurrentCompany(this.company);
        snackBarMessage.showSuccess('Company successfully created.');
        if (this.$isBgCloud) {
          this.$router.push(`/${this.company.id}/selectplan`);
        } else {
          this.$router.push(`/${this.company.id}/devices`);
        }
      } catch (err) {
        if (this.company) {
          await this.removeCompany([this.company.id, {}]);
        }
        this.throwVtulError(operationalUiErrors.UNCREATED_COMPANY, alertBox.SNACKBAR.value, err);
      }
      this.isLoading = false;
      this.isFullLoading = false;
    },
    async fetchMembershipFromApi() {
      await this.findMemberships({
        query: {
          userId: this.$store.getters['auth/user'].id,
        },
      });
      this.membershipFetched = true;
    },
  },
};
</script>
<style scoped>

.material-card {
  margin: 0 auto;
}

</style>
